const FooterLinksL: any = {
  privacy: {
    'us': 'Privacy',
    'no': 'Personvern',
  },
  terms: {
    'us': 'Terms',
    'no': 'Vilkår',
  }
};

export default FooterLinksL;
