import { AppButtonsAppsI } from "../../interfaces/app";

const AppButtonsL: AppButtonsAppsI = {
  akitaTV: [{
    img: '/icon-transparent-64x64.png',
    label: {
      'us': 'Get started',
      'no': 'Kom i gang',
    },
    title: {
      'us': 'Web - Visit www.akitatv.com',
      'no': 'Web - Besøk www.akitatv.com',
    },
    url: 'https://www.akitatv.com/'
  }, {
    img: 'https://upload.wikimedia.org/wikipedia/commons/5/55/Google_Play_2016_icon.svg',
    label: {
      'us': 'Android',
      'no': 'Android',
    },
    title: {
      'us': 'Android - Download from Google Play',
      'no': 'Android - Last ned fra Google Play',
    },
    url: 'https://play.google.com/store/apps/details?id=com.akita.tv'
  }, {
    img: 'https://upload.wikimedia.org/wikipedia/commons/archive/3/31/20220116095717%21Apple_logo_white.svg',
    label: {
      'us': 'iOS',
      'no': 'iOS',
    },
    title: {
      'us': 'iOS - Download from App Store',
      'no': 'iOS - Last ned fra App Store',
    },
    url: 'https://apps.apple.com/us/app/akita-tv/id1570949221'
  }],
  voyaPlayer: []
};

export default AppButtonsL;