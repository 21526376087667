import { BehaviorSubject } from 'rxjs';

import ExternalUrlsC from "../const/external-urls";
import SettingsC from "../const/settings";

import StorageService from "./StorageService";
import UtilsService from "./UtilsService";

class SettingsService {

  private static isDarkMode: boolean;

  private static get isDarkModePreferred(): boolean {
      return Boolean(matchMedia && matchMedia('(prefers-color-scheme: dark)').matches);
  }

  public static get IsDarkMode(): boolean {
    if (!UtilsService.IsNullOrUndefined(SettingsService.isDarkMode)) {
      return SettingsService.isDarkMode;
    }

    const darkMode     = StorageService.Get(SettingsC.IsDarkMode);
    const defaultValue = SettingsService.isDarkModePreferred;

    SettingsService.IsDarkMode = UtilsService.GetValueIfDefined(darkMode, defaultValue);

    return SettingsService.isDarkMode;
  }

  public static set IsDarkMode(darkMode: boolean) {
    SettingsService.isDarkMode = darkMode;

    StorageService.Set(SettingsC.IsDarkMode, darkMode);
  }

  public static Language$ = new BehaviorSubject('');

  public static get Language(): string {
    if (SettingsService.Language$.value) {
      return SettingsService.Language$.value;
    }

    const lang = StorageService.Get(SettingsC.Language);

    SettingsService.Language = UtilsService.GetValueIfDefined(lang, 'us');

    return SettingsService.Language$.value;
  }

  public static set Language(lang: string) {
    SettingsService.Language$.next(lang);

    StorageService.Set(SettingsC.Language, lang);
  }

  public static GetLanguageFlag(): string {
    // https://flagpedia.net/download/api
    return `${ExternalUrlsC.countryFlagIcons}h20/${SettingsService.Language}.png`;
  }

  public static ApplyDarkMode(darkMode: boolean) {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    SettingsService.IsDarkMode = darkMode;
  }
}

export default SettingsService;
