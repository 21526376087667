import AboutC from "../const/about";

const AboutL: any = {
  education: {
    'us': 'Education',
    'no': 'Utdanning',
  },
  workExperience: {
    'us': 'Work Experience',
    'no': 'Arbeidserfaring',
  },
  photoRef: {
    'us': 'Background by',
    'no': 'Bakgrunn av',
  },
  portrait: {
    'us': `Portrait of the person ${AboutC.author}`,
    'no': `Portrett av personen ${AboutC.author}`,
  },
  role: {
    'us': 'Senior Software Developer',
    'no': 'Senior Programvareutvikler',
  },
};

export default AboutL;
