const ExternalTitlesL: any = {
  facebook: {
    'us': 'Share on Facebook',
    'no': 'Del på Facebook',
  },
  linkedin: {
    'us': 'Share on LinkedIn',
    'no': 'Del på Facebook',
  },
  mail: {
    'us': 'Share via Mail',
    'no': 'Del via Mail',
  },
  twitter: {
    'us': 'Share on Twitter',
    'no': 'Del på Twitter',
  },
};

export default ExternalTitlesL;
