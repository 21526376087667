import FooterLinksL from "../lang/footer-links";

const FooterLinksC = {
  privacy: {
    title: FooterLinksL.privacy,
    url: '/privacy'
  },
  terms: {
    title: FooterLinksL.terms,
    url: '/terms'
  }
};

export default FooterLinksC;
