
import { useState } from 'react';

import { AppFeatureI } from '../../../interfaces/app';

import SettingsService from '../../../services/SettingsService';

import ModalImage from '../../modal-image/ModalImage';

import './AppFeatures.scss';

function AppFeatures(props: { features?: Array<AppFeatureI> }) {
  const [ image, setImage ] = useState('');

  if (!props.features || props.features.length < 1) {
    return null;
  }

  return (
    <div id="AppFeatures">
      <ModalImage img={image} onClose={() => setImage("")} />

      {props.features.map((feature, i) => (i % 2 === 0 ? (
        <div key={i} className="flex left">
            <div className="flex-1 middle padding-20">
              <div className="center max-500">
                <h1>{feature.title[SettingsService.Language]}</h1>
                <h2>{feature.description[SettingsService.Language]}</h2>
              </div>
            </div>

            <div className="flex-1 middle padding-20">
              <figure onClick={() => setImage(feature.img)}>
                <img className="clickable responsive max-500" src={feature.img} alt="Screenshot of the app feature" loading="lazy" />
              </figure>
            </div>
          </div>
        ) : (
          <div key={i} className="flex right">
            <div className="flex-1 middle padding-20">
              <figure onClick={() => setImage(feature.img)}>
                <img className="clickable responsive max-500" src={feature.img} alt="Screenshot of the app feature" loading="lazy" />
              </figure>
            </div>

            <div className="flex-1 middle padding-20">
              <div className="center max-500">
                <h1>{feature.title[SettingsService.Language]}</h1>
                <h2>{feature.description[SettingsService.Language]}</h2>
              </div>
            </div>
          </div>
        )
      ))}
    </div>
  );
}

export default AppFeatures;
