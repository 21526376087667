import { AppFAQI } from "../../interfaces/app";

import AppFAQL from "../../lang/app/app-faqs";

const AppFAQC = new Map<string, Array<AppFAQI>>([
  [ "akita-tv", AppFAQL.akitaTV ],
  [ "voya-player", AppFAQL.voyaPlayer ]
]);

export default AppFAQC;
