import { AppDetailsAppsI } from "../../interfaces/app";

const AppDetailsL: AppDetailsAppsI = {
  akitaTV: {
    id: 'com.akitatv.com',
    img: '/tv/discover.webp',
    title: 'Akita TV',
    url: 'https://www.akitatv.com/',
    description: {
      'us': 'Akita TV is a web and mobile app that makes it easy to discover new and popular movies and TV shows, and keep track of what you have watched so far.',
      'no': 'Akita TV er en web- og mobilapp som gjør det enkelt å oppdage nye og populære filmer og TV-serier, og holde oversikt over hva du har sett så langt.',
    },
    subHeader: {
      'us': '',
      'no': '',
    }
  },
  voyaPlayer: {
    id: '',
    img: '',
    title: 'Voya Player',
    url: '',
    description: {
      'us': '',
      'no': '',
    },
    subHeader: {
      'us': '',
      'no': '',
    }
  }
};

export default AppDetailsL;