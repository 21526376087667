import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AppButtonI, AppDetailsI, AppFAQI, AppFeatureI } from '../../interfaces/app';

import AboutC from '../../const/about';
import AppButtonsC from '../../const/app/app-buttons';
import AppDetailsC from '../../const/app/app-details';
import AppFAQC from '../../const/app/app-faqs';
import AppFeaturesC from '../../const/app/app-features';

import AboutL from '../../lang/about';

import SettingsService from '../../services/SettingsService';

import AppButtons from '../../components/app/app-buttons/AppButtons';
import AppFAQ from '../../components/app/app-faq/AppFAQ';
import AppFeatures from '../../components/app/app-features/AppFeatures';

import './Apps.scss';

function Apps() {
  const { id } = useParams();

  const [ appButtons,  setAppButtons ]  = useState<Array<AppButtonI> | undefined>();
  const [ appDetails,  setAppDetails ]  = useState<AppDetailsI | undefined>();
  const [ appFAQ,      setAppFAQ ]      = useState<Array<AppFAQI> | undefined>();
  const [ appFeatures, setAppFeatures ] = useState<Array<AppFeatureI> | undefined>();
  const [ appID,       setAppID ]       = useState<string>();
  const [ headerIndex, setHeaderIndex ] = useState(0);

  useEffect(() => {
    if (id) {
      const features = AppFeaturesC.get(id);

      setAppButtons(AppButtonsC.get(id));
      setAppDetails(AppDetailsC.get(id));
      setAppFAQ(AppFAQC.get(id));
      setAppFeatures(features);  
      setAppID(id);

      setTimeout(() => {
        if (features) {
          setHeaderIndex((headerIndex + 1) % features.length);
        }
      }, 5000);
    }
  }, [ id, headerIndex ])

  if (!appID || !appDetails || !appFeatures) {
    return null;
  }

  return (
    <div id="Apps" itemScope itemType="https://schema.org/SoftwareApplication">
      <header>
        <meta itemProp="applicationCategory" content="EntertainmentApplication" />
        <meta itemProp="description" content={appDetails.description[SettingsService.Language]} />
        <meta itemProp="identifier" content={appDetails.id} />
        <meta itemProp="image" content={appDetails.img} />
        <meta itemProp="url" content={appDetails.url} />

        <span itemScope itemProp="aggregateOffer" itemType="https://schema.org/AggregateOffer">
          <meta itemProp="offerCount" content="0" />
        </span>

        <span itemScope itemProp="author" itemType="https://schema.org/Person">
          <meta itemProp="description" content={AboutL.role[SettingsService.Language]} />
          <meta itemProp="image" content={`${AboutC.url}image.jpg`} />
          <meta itemProp="name" content={AboutC.author} />
          <meta itemProp="url" content={AboutC.url} />
        </span>

        <div
          id="AppHeader"
          style={{ backgroundImage: `url(${appFeatures[headerIndex].background})` }}
        >
          <div className="overlay">
            <div className="center max-600">
              <h1 itemProp="name">{appDetails.title}</h1>
              <h2>{appDetails.description[SettingsService.Language]}</h2>
              <h3>{appDetails.subHeader[SettingsService.Language]}</h3>

              <AppButtons buttons={appButtons} isDark={true} />
            </div>
          </div>
        </div>
      </header>

      <main className="page">
        <AppFeatures features={appFeatures} />
        <AppFAQ faqs={appFAQ} />
        <AppButtons buttons={appButtons} />
      </main>
    </div>
  );
}

export default Apps;
