import UtilsService from "./UtilsService";

class StorageService {

  public static Clear() {
    localStorage.clear();
  }

  public static Get(setting: string): any {
    const value = localStorage.getItem(setting);

    return (value ? JSON.parse(value) : null);
  }

  public static Remove(setting: string) {
    if (setting) {
      localStorage.removeItem(setting);
    }
  }

  public static Set(setting: string, value: any) {
    if (!UtilsService.IsNullOrUndefined(value)) {
      const valueS = JSON.stringify(value);

      try {
        localStorage.setItem(setting, valueS);
      } catch (e) {
        this.Clear();

        localStorage.setItem(setting, valueS);
      }
    }
  }

}

export default StorageService;
