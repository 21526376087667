import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArticleContentsC, ArticleGroupsC, ArticleTitlesC } from '../../const/articles';
import NavLinksC from '../../const/nav-links';

import Markdown from '../../components/markdown/Markdown';

import './Articles.scss';

function Articles() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [ articleContent, setArticleContent ] = useState('');
  const [ articleID,      setArticleID ]      = useState('');

  useEffect(() => {
    if (id) {
      setArticleID(id);
      setArticleContent(ArticleContentsC.get(id));
    } else {
      for (const group of ArticleGroupsC) {
        for (const aid of group.articles) {
          setArticleID(aid);
          setArticleContent(ArticleContentsC.get(aid));
          break;
        }
        break;
      }
    }
  }, [ id ])

  function getArticles(): Array<ReactElement> {
    const articles: Array<ReactElement> = [];

    ArticleGroupsC.forEach((group, i) => {
      articles.push(<optgroup key={i} label={group.group} disabled></optgroup>)

      group.articles.forEach((id: string, j) => {
        articles.push(<option key={`${i}-${j}`} value={id}>{ArticleTitlesC.get(id)}</option>);
      });
    });

    return articles;
  }

  function navigateTo(id: string) {
    navigate(`${NavLinksC.articles.url}/${id}`, { replace: true });
  }

  return (
    <div id="Articles" className="center max-900 page">
      <header>
        <select value={articleID} onChange={(e) => navigateTo(e.target.value)}>
          {getArticles()}
        </select>
      </header>

      <main>
        {articleContent &&
          <Markdown id={articleID} src={articleContent} setSrc={(id: string) => navigateTo(id)} />
        }
      </main>
    </div>
  );
}

export default Articles;
