import './ModalImage.scss';

function ModalImage(props: { img: string, onClose: any }) {
  if (!props.img) {
    return null;
  }

  return (
    <div id="ModalImage" className="center flex flex-vertical full-size">
      <div id="ModalImageClose" className="clickable" onClick={props.onClose}>&times;</div>

      <figure><img src={props.img} alt="Full size version" /></figure>
    </div>
  );
}

export default ModalImage;
