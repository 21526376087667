import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import App from './App';

import './index.scss';

const root = (document.getElementById('root') as Element);

ReactDOMClient.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
