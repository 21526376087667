const NavLinksL: any = {
  about: {
    'us': 'ABOUT',
    'no': 'OM',
  },
  apps: {
    'us': 'APPS',
    'no': 'APPER',
  },
  articles: {
    'us': 'ARTICLES',
    'no': 'ARTIKLER',
  },
  home: {
    'us': 'HOME',
    'no': 'HJEM',
  },
};

export default NavLinksL;
