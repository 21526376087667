import { AppButtonI } from '../../../interfaces/app';

import SettingsService from '../../../services/SettingsService';

import './AppButtons.scss';

function AppButtons(props: { buttons?: Array<AppButtonI>, isDark?: boolean }) {
  if (!props.buttons || (props.buttons.length < 1)) {
    return null;
  }

  const dividerMaxIndex = (props.buttons.length - 1);

  return (
    <div className={'center flex padding-10 app-buttons' + (props.isDark ? '-dark' : '')}>
      <a className="padding-10" href={props.buttons[0].url} target="_blank" rel="noreferrer noopener">
        <div
          className={'app-button' + (props.isDark ? '-dark' : '')}
          title={props.buttons[0].title[SettingsService.Language]}
        >{props.buttons[0].label[SettingsService.Language]}</div>
      </a>

      <div className="flex flex-nowrap">
        {props.buttons.map((button, i) => (i > 0 &&
          <div key={i} className="flex flex-nowrap middle">
            <a
              className="middle"
              title={button.title[SettingsService.Language]}
              href={button.url} target="_blank" rel="noreferrer noopener"
            >{button.label[SettingsService.Language]}</a>

            {i < dividerMaxIndex &&
              <span className="divider"></span>
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default AppButtons;
