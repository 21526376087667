import { AppFAQsI } from "../../interfaces/app";

function getImage(url: string, alt: string) {
  return `<img src="${url}" alt="${alt}" loading="lazy" />`;
}

function getLink(url: string, label: string) {
  return `<a href="${url}" target="_blank" rel="noreferrer noopener">${label}</a>`;
}

export const AppFAQL: AppFAQsI = {
  title: {
    'us': 'Frequently Asked Questions',
    'no': 'Ofte Stilte Spørsmål',
  },
  akitaTV: [{
  question: {
    'us': 'What can Akita TV do?',
    'no': 'Hva kan Akita TV gjøre?',
  },
  answers: [{
    'us': 'Discover new and interesting movies and TV shows to watch.',
    'no': 'Oppdag nye og interessante filmer og TV-serier å se.',
  }, {
    'us': 'Get an overview of what you have watched so far.',
    'no': 'Få oversikt over hva du har sett så langt.',
  }, {
    'us': 'Find out when and where new releases are airing.',
    'no': 'Finn ut når og hvor nye utgivelser sendes.',
  }]}, {
  question: {
    'us': 'Can I watch full-length movies or TV shows?',
    'no': 'Kan jeg se filmer eller TV-serier i full lengde?',
  },
  answers: [{
    'us': 'No. Akita TV is not a streaming service and does not own any licenses.',
    'no': 'Nei. Akita TV er ikke en strømmetjeneste og eier ingen lisenser.',
  }]}, {
  question: {
    'us': 'Does it show ads or track my private data?',
    'no': 'Vises det annonser eller spores mine private data?',
  },
  answers: [{
    'us': 'No. Your activity, favorites, watch progress etc. are not visible to other users or any third party.',
    'no': 'Nei. Din aktivitet, favoritter, fremgang osv. er ikke synlig for andre brukere eller tredjeparter.',
  }]}, {
  question: {
    'us': 'How much does it cost?',
    'no': 'Hvor mye koster det?',
  },
  answers: [{
    'us': 'It\'s free to sign up and get started.',
    'no': 'Det er gratis å registrere seg og komme i gang.',
  }, {
    'us': 'A Premium account costs only $4.99.',
    'no': 'En Premium-konto koster bare $4.99.',
  }]}, {
  question: {
    'us': 'What is included for free?',
    'no': 'Hva er inkludert gratis?',
  },
  answers: [{
    'us': `Search and discover movies, TV shows and people (powered by ${getLink('https://www.themoviedb.org/', 'TMDb')}).`,
    'no': `Søk og oppdag filmer, TV-serier og personer (drevet av ${getLink('https://www.themoviedb.org/', 'TMDb')}).`,
  }, {
    'us': 'Add up to 10 favorites.',
    'no': 'Legg til opptil 10 favoritter.',
  }]}, {
  question: {
    'us': 'What is included with a Premium account?',
    'no': 'Hva er inkludert i en Premium-konto?',
  },
  answers: [{
    'us': 'Add more than 10 favorites.',
    'no': 'Legg til flere enn 10 favoritter.',
  }, {
    'us': 'Organize favorites with custom lists.',
    'no': 'Organiser favoritter med egendefinerte lister.',
  }, {
    'us': 'Get notified when your favorites are coming to theatres or airing on streaming services.',
    'no': 'Bli varslet når dine favoritter kommer på kino eller sendes på strømmetjenester.',
  }]}, {
  question: {
    'us': 'Do I need a separate Premium account per device?',
    'no': 'Trenger jeg en egen Premium-konto per enhet?',
  },
  answers: [{
    'us': 'No, your account will be linked to your e-mail address.',
    'no': 'Nei, kontoen din blir tilknyttet e-postadressen din.',
  }, {
    'us': 'You only need to upgrade once, and it will work across devices.',
    'no': 'Du trenger bare å oppgradere en gang, så fungerer det på tvers av enheter.',
  }]}, {
  question: {
    'us': 'Can I delete my account?',
    'no': 'Kan jeg slette kontoen min?',
  },
  answers: [{
    'us': `Yes. You can manage your account by clicking on the arrow icon next to your email address on the profile page.<p>${getImage('/tv/delete_account.webp', 'Screenshot showing how you can delete your account')}</p>`,
    'no': `Ja. Du kan håndtere kontoen din ved å klikke på pilen ved siden av e-postaddressen din på profilsiden.<p>${getImage('/tv/delete_account.webp', 'Skjermbilde som viser hvordan du kan slette kontoen din')}</p>`
  }]}],
  voyaPlayer: []
}

export default AppFAQL;
