
import { AppFAQI } from '../../../interfaces/app';

import AppFAQL from '../../../lang/app/app-faqs';

import SettingsService from '../../../services/SettingsService';

import './AppFAQ.scss';

function AppFAQ(props: { faqs?: Array<AppFAQI> }) {
  if (!props.faqs || props.faqs.length < 1) {
    return null;
  }

  return (
    <div id="AppFAQ">
      <h1>{AppFAQL.title[SettingsService.Language]}</h1>

      <div className="center max-600">
        {props.faqs.map((faq, i) => (
            <details key={i}>
              <summary
                className="clickable title"
              >{faq.question[SettingsService.Language]}</summary>

              {faq.answers.map((answer, j) => (
                <p
                  key={j}
                  className="answer"
                  dangerouslySetInnerHTML={{
                    __html: answer[SettingsService.Language]
                  }}>
                </p>
              ))}
            </details>
        ))}
      </div>
    </div>
  );
}

export default AppFAQ;
