import { AppButtonI } from "../../interfaces/app";

import AppButtonsL from "../../lang/app/app-buttons";

const AppButtonsC = new Map<string, Array<AppButtonI>>([
  [ "akita-tv", AppButtonsL.akitaTV ],
  [ "voya-player", AppButtonsL.voyaPlayer ]
]);

export default AppButtonsC;
