import { ArticleGroupI } from '../interfaces/article'

import angular from '../articles/angular.md'
import icloud from '../articles/icloud.md'
import iosCopyMusic from '../articles/ios-copy-music.md'
import iosCopyPhotos from '../articles/ios-copy-photos.md'
import itunes from '../articles/itunes.md'
import nodejs from '../articles/nodejs.md'
import react from '../articles/react.md'
import vscode from '../articles/vscode.md'
import vue from '../articles/vue.md'

export const ArticleIdsC = {
  angular: 'how-to-setup-a-simple-angular-project-with-routing',
  react: 'how-to-setup-a-simple-react-project-with-routing',
  vue: 'how-to-setup-a-simple-vue-project-with-routing',
  iosCopyMusic: 'how-to-copy-music-files-to-iphone-ipad',
  iosCopyPhotos: 'how-to-copy-photos-to-iphone-ipad',
  icloud: 'how-to-install-icloud',
  itunes: 'how-to-install-itunes',
  nodejs: 'how-to-install-nodejs',
  vscode: 'how-to-install-vs-code'
};

export const ArticleContentsC = new Map<string, any>([
  [ ArticleIdsC.angular, angular ],
  [ ArticleIdsC.react, react ],
  [ ArticleIdsC.vue, vue ],
  [ ArticleIdsC.iosCopyMusic, iosCopyMusic ],
  [ ArticleIdsC.iosCopyPhotos, iosCopyPhotos ],
  [ ArticleIdsC.icloud, icloud ],
  [ ArticleIdsC.itunes, itunes ],
  [ ArticleIdsC.nodejs, nodejs ],
  [ ArticleIdsC.vscode, vscode ]
]);

export const ArticleHashTagsC = new Map<string, string>([
  [ ArticleIdsC.angular, '#js #javascript #typescript #angular #routing' ],
  [ ArticleIdsC.react, '#js #javascript #typescript #react #routing' ],
  [ ArticleIdsC.vue, '#js #javascript #typescript #vue #routing' ],
  [ ArticleIdsC.iosCopyMusic, '#apple #ios #iphone #ipad #music' ],
  [ ArticleIdsC.iosCopyPhotos, '#apple #ios #iphone #ipad #photos' ],
  [ ArticleIdsC.icloud, '#apple #icloud' ],
  [ ArticleIdsC.itunes, '#apple #itunes' ],
  [ ArticleIdsC.nodejs, '#nodejs' ],
  [ ArticleIdsC.vscode, '#vscode' ]
]);

export const ArticleGroupsC: Array<ArticleGroupI> = [{
    group: 'Simple Web Apps with Routing',
    articles: [
      ArticleIdsC.angular,
      ArticleIdsC.react,
      ArticleIdsC.vue
    ]
  }, {
    group: 'Copying files to iOS devices',
    articles: [
      ArticleIdsC.iosCopyMusic,
      ArticleIdsC.iosCopyPhotos
    ]
  }, {
    group: 'Pre-requisites',
    articles: [
      ArticleIdsC.icloud,
      ArticleIdsC.itunes,
      ArticleIdsC.nodejs,
      ArticleIdsC.vscode
    ]
  }
];

export const ArticleTitlesC = new Map<string, string>([
  [ ArticleIdsC.angular, 'How to setup a simple Angular project with Routing' ],
  [ ArticleIdsC.react, 'How to setup a simple React project with Routing' ],
  [ ArticleIdsC.vue, 'How to setup a simple Vue project with Routing' ],
  [ ArticleIdsC.iosCopyMusic, 'How to copy music files to iPhone/iPad' ],
  [ ArticleIdsC.iosCopyPhotos, 'How to copy photos to iPhone/iPad' ],
  [ ArticleIdsC.icloud, 'How to install iCloud' ],
  [ ArticleIdsC.itunes, 'How to install iTunes' ],
  [ ArticleIdsC.nodejs, 'How to install Node.js' ],
  [ ArticleIdsC.vscode, 'How to install VS Code' ]
]);
