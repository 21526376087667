class UtilsService {

  public static GetValueIfDefined(value: any, defaultValue: any): any {
    return (!UtilsService.IsNullOrUndefined(value) ? value : defaultValue);
  }

  public static IsNullOrUndefined(value: any): boolean {
    return ((value === null) || (value === undefined));
  }

}

export default UtilsService;
