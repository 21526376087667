import AboutC from '../../const/about';

import AboutL from '../../lang/about';
import TimelineItemsL from '../../lang/timeline-items';

import SettingsService from '../../services/SettingsService';

import Timeline from '../../components/timeline/Timeline';

import './About.scss';

function About() {
  return (
    <div
      id="About"
      className="center max-900 page"
      itemScope itemType="https://schema.org/Person"
    >
      <meta itemProp="url" content={AboutC.url} />

      <header className="padding-bottom-20">
        <div className="center flex">
          <figure className="middle">
            <img
              className="img-circle"
              itemProp="image"
              src="/image.jpg"
              alt={AboutL.portrait[SettingsService.Language]} loading="lazy"
            />
          </figure>

          <div className="middle padding-20">
            <h1 itemProp="name">{AboutC.author}</h1>
            <h2 itemProp="description">{AboutL.role[SettingsService.Language]}</h2>
          </div>
        </div>
      </header>

      <main>
        <Timeline
          title={AboutL.education[SettingsService.Language]}
          items={TimelineItemsL.education}
        />

        <Timeline
          title={AboutL.workExperience[SettingsService.Language]}
          items={TimelineItemsL.work}
        />
      </main>
    </div>
  );
}

export default About;
