import SettingsService from "../services/SettingsService";

export const NavL: any = {
  CountryFlagAlt: {
    'us': 'Flag representing the selected language',
    'no': 'Flagg som representerer valgt språk',
  },
  LanguageTitle: {
    'us': 'Select language',
    'no': 'Velg språk',
  },
  LogoAlt: {
    'us': 'Logo linking to the home page',
    'no': 'Logo som linker til hjemmesiden',
  },
  ScrollToTop: {
    'us': 'TO THE TOP',
    'no': 'TIL TOPPEN',
  }
};

export function GetDarkModeTitle(): string {
  if (SettingsService.Language === 'no') {
    return `Bytt til ${SettingsService.IsDarkMode ? 'Lyst': 'Mørkt'} fargemodus`;
  }

  return `Switch to ${SettingsService.IsDarkMode ? 'Light': 'Dark'} mode`;
}
