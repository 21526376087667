import { AppFeaturesI } from "../../interfaces/app";

const AppFeaturesL: AppFeaturesI = {
  akitaTV: [{
    title: {
      us: 'Search and discover',
      no: 'Søk og oppdag'
    },
    description: {
      us: 'Search for specific titles, or discover popular movies and TV shows.',
      no: 'Søk etter bestemte titler, eller oppdag populære filmer og TV-serier.'
    },
    img: '/tv/home.webp',
    background: '/tv/background-home.jpg'
  }, {
    title: {
      us: 'Discover what\'s streaming',
      no: 'Oppdag hva som strømmer'
    },
    description: {
      us: 'Discover which movies and TV shows are available on different streaming services.',
      no: 'Oppdag hvilke filmer og TV-serier som er tilgjengelige på ulike strømmetjenester.'
    },
    img: '/tv/discover-streaming.webp',
    background: '/tv/background-discover-streaming.jpg'
  }, {
    title: {
      us: 'Favorites and episodes',
      no: 'Favoritter og episoder'
    },
    description: {
      us: 'Add movies and shows to your favorites, and keep track of what you have watched so far.',
      no: 'Legg filmer og serier til i favorittene dine, og hold oversikt over hva du har sett så langt.'
    },
    img: '/tv/favorites-new.webp',
    background: '/tv/background-favorites-new.jpg'
  }, {
    title: {
      us: 'Custom lists',
      no: 'Egendefinerte lister'
    },
    description: {
      us: 'Create your own lists, and add your favorite movies and TV shows.',
      no: 'Opprett dine egne lister, og legg til dine favorittfilmer og TV-serier.'
    },
    img: '/tv/favorites-lists.webp',
    background: '/tv/background-favorites-lists.jpg'
  }],
  voyaPlayer: [],
};

export default AppFeaturesL;
