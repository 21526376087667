import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Link, useLocation } from 'react-router-dom';

import AboutC from '../../const/about';
import FooterLinksC from '../../const/footer-links';

import SettingsService from '../../services/SettingsService';

import './Footer.scss';

function Footer() {
  const location = useLocation().pathname;

  function getClass(path: string): string {
    return (location === path ? 'selected' : '');
  }

  return (
    <footer id="Footer" className="flex flex-reverse">
      <div
        className="color-gray flex-1 left padding-10 text-nowrap"
      >&copy; {AboutC.author} ({AboutC.company})</div>

      <ul className="center flex flex-1 flex-nowrap links">
        <li>
          <Link
            to={FooterLinksC.privacy.url}
            className={getClass(FooterLinksC.privacy.url)}
          >{FooterLinksC.privacy.title[SettingsService.Language]}</Link>
        </li>
        <li>
          <Link
            to={FooterLinksC.terms.url}
            className={getClass(FooterLinksC.terms.url)}
          >{FooterLinksC.terms.title[SettingsService.Language]}</Link>
        </li>
      </ul>

      <ul className="flex flex-1 flex-nowrap right icons">
        <li>
          <a href={AboutC.github} target="_blank" rel="noreferrer noopener" title="GitHub">
            <FontAwesomeIcon icon={brands('github')} />
          </a>
        </li>
        <li>
          <a href={AboutC.linkedin} target="_blank" rel="noreferrer noopener" title="LinkedIn">
            <FontAwesomeIcon icon={brands('linkedin')} />
          </a>
        </li>
        <li>
          <a href={AboutC.hackerrank} target="_blank" rel="noreferrer noopener" title="HackerRank">
            <FontAwesomeIcon icon={brands('hackerrank')} />
          </a>
        </li>
        <li>
          <a href={AboutC.twitter.url} target="_blank" rel="noreferrer noopener" title="Twitter">
            <FontAwesomeIcon icon={brands('twitter')} />
          </a>
        </li>
        <li>
          <a href={AboutC.mail} title="Mail">
            <FontAwesomeIcon icon={solid('envelope')} />
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
