import { AppFeatureI } from "../../interfaces/app";

import AppFeaturesL from "../../lang/app/app-features";

const AppFeaturesC = new Map<string, Array<AppFeatureI>>([
  [ "akita-tv", AppFeaturesL.akitaTV ],
  [ "voya-player", AppFeaturesL.voyaPlayer ]
]);

export default AppFeaturesC;
