import AboutC from '../../const/about';

import AboutL from '../../lang/about';

import SettingsService from '../../services/SettingsService';

import './Home.scss';

function Home() {
  return (
    <div id="Home" className="full-size">
      <main className="flex flex-vertical full-size overlay">
        <div className="flex-1 flex flex-vertical middle padding-top-10">
          <figure>
            <img
              className="img-circle"
              src="/image.jpg"
              alt={AboutL.portrait[SettingsService.Language]} loading="lazy"
            />
          </figure>

          <h2>{AboutC.author}</h2>
          <h1>{AboutC.company}</h1>
          <h2>{AboutL.role[SettingsService.Language]}</h2>
        </div>

        <div id="PhotoRef">
          {AboutL.photoRef[SettingsService.Language]} <a
            href="https://unsplash.com/photos/w33-zg-dNL4"
            target="_blank" rel="noreferrer noopener"
          >@rami_alzayat</a>
        </div>
      </main>
    </div>
  );
}

export default Home;
