import { TimelineItemsI } from "../interfaces/timeline";

const shared = {
  akitaTV: 'Angular &bull; Firebase &bull; Ionic &bull; NoSQL &bull; OAuth &bull; PWA &bull; TypeScript',
  bouvet: 'AngularJS &bull; React &bull; ASP.NET MVC &bull; C# &bull; Azure &bull; Firebase',
  bachelor: 'Android &bull; C++ &bull; C# &bull; Java &bull; OpenGL &bull; React Native &bull; SFML &bull; Unity3D',
  cppLibs: 'C++ &bull; FFmpeg &bull; libAOM &bull; libXML2 &bull; SDL2 &bull; zLib',
  equinor: 'ASP.NET MVC &bull; Azure &bull; HoloLens &bull; iOS &bull; Mixed Reality &bull; Unity 3D',
  stages: 'Angular &bull; ASP.NET MVC &bull; Azure &bull; Raspberry Pi &bull; Video.js &bull; WebRTC'
};

function getLink(url: string, label: string) {
  return `<a href="${url}" target="_blank" rel="noreferrer noopener">${label}</a>`;
}

const TimelineItemsL: TimelineItemsI = {
  education: [{
    title: {
      'us': 'Bachelor of Game Programming',
      'no': 'Bachelor i Spillprogrammering'
    },
    location: {
      'us': `${getLink('https://www.ntnu.edu/', 'Norwegian University of Science and Technology - NTNU')} | Norway | 2015 - 2018 | GPA: 3.29/4.0 (B+)`,
      'no': `${getLink('https://www.ntnu.no/', 'Norges teknisk-naturvitenskapelige universitet - NTNU')} | Norge | 2015 - 2018 | GPA: 3.29/4.0 (B+)`
    },
    description: {
      'us': `Algorithms &bull; Artificial Intelligence &bull; Computer Science &bull; Databases &bull; Game Design &bull; Mathematics &bull; Network &bull; Operating Systems<br />${shared.bachelor}`,
      'no': `Algoritmer &bull; Databaser &bull; Datavitenskap &bull; Kunstig Intelligens &bull; Matematikk &bull; Nettverk &bull; Operativsystemer &bull; Spilldesign<br />${shared.bachelor}`
    }
  }, {
    title: {
      'us': 'Psychology 1-Year',
      'no': 'Psykologi Årsstudium'
    },
    location: {
      'us': `${getLink('https://en.uit.no/', 'The Arctic University of Norway - UiT')} | Norway | 2012 - 2013 | GPA: 4.0 (A)`,
      'no': `${getLink('https://uit.no/', 'Norges arktiske universitet - UiT')} | Norge | 2012 - 2013 | GPA: 4.0 (A)`
    },
    description: {
      'us': 'Health Sciences &bull; Philosophy &bull; Psychology',
      'no': 'Filosofi &bull; Helsevitenskap &bull; Psykologi'
    }
  }],
  work: [{
    title: {
      'us': 'Self-Employed',
      'no': 'Selvstendig næringsdrivende',
    },
    location: {
      'us': '<span class="title">Jammary Studio</span> | 2023 - 2024',
      'no': '<span class="title">Jammary Studio</span> | 2023 - 2024'
    },
    description: {
      'us': `Developed and released ${getLink('https://github.com/adamajammary/libsdl2gui', 'libsdl2gui')}, ${getLink('https://github.com/adamajammary/libvoyaplayer', 'libvoyaplayer')} and ${getLink('https://www.akitatv.com/', 'Akita TV v2')}.<br />${shared.cppLibs}`,
      'no': `Utviklet og utgitt ${getLink('https://github.com/adamajammary/libsdl2gui', 'libsdl2gui')}, ${getLink('https://github.com/adamajammary/libvoyaplayer', 'libvoyaplayer')} og ${getLink('https://www.akitatv.com/', 'Akita TV v2')}.<br />${shared.cppLibs}`
    }
  }, {
    title: {
      'us': 'Senior Consultant',
      'no': 'Seniorkonsulent'
    },
    location: {
      'us': `${getLink('https://en.bouvet.no/', 'Bouvet ASA')} | Norway | 2022 - 2023`,
      'no': `${getLink('https://www.bouvet.no/', 'Bouvet ASA')} | Norge | 2022 - 2023`
    },
    description: {
      'us': `Worked as a senior full-stack developer on several software solutions for Equinor and Lyse Energi.<br />
      ${shared.bouvet}<br />
      <br />
      ${getLink('https://www.equinor.com/', 'Equinor ASA')}<br />
      - Worked on three projects; two web-based and one desktop app.<br />
      - Frontend development by migrating one project from AngularJS to React.<br />
      - Backend development using ASP.NET Core MVC Web API in C# and hosted with Azure Web Apps.<br />
      <br />
      ${getLink('https://www.lyse.no/kundeservice/english', 'Lyse Energi AS')}<br />
      - Backend development using ASP.NET Core MVC Web API in C# and hosted with Azure Container Apps.<br />
      - Developed push notifications to the mobile app with Azure Notification Hub and Firebase Cloud Messaging.<br />
      - Developed real-time data delivery from HAN devices and Smart Meters to the mobile app using Azure Functions, Service Bus queues and Web PubSub (WPS) service.`,
      'no': `Jobbet som senior full-stack utvikler på flere programvareløsninger for Equinor og Lyse Energi.<br />
      ${shared.bouvet}<br />
      <br />
      ${getLink('https://www.equinor.com/no', 'Equinor ASA')}<br />
      - Jobbet på tre prosjekter; to nettbaserte og en desktop app.<br />
      - Frontend-utvikling ved å migrere ett prosjekt fra AngularJS til React.<br />
      - Backend-utvikling med ASP.NET Core MVC Web API i C# og hostet med Azure Web Apps.<br />
      <br />
      ${getLink('https://www.lyse.no/', 'Lyse Energi AS')}<br />
      - Backend-utvikling med ASP.NET Core MVC Web API i C# og hostet med Azure Container Apps.<br />
      - Utviklet push-varsler til mobilappen med Azure Notification Hub og Firebase Cloud Messaging.<br />
      - Utviklet sanntidslevering av data fra HAN-enheter og smarte strømmålere til mobilappen med Azure Functions, Service Bus køer og Web PubSub (WPS) tjeneste.`,
    }
  }, {
    title: {
      'us': 'Self-Employed',
      'no': 'Selvstendig næringsdrivende',
    },
    location: {
      'us': '<span class="title">Jammary Studio</span> | 2020 - 2022',
      'no': '<span class="title">Jammary Studio</span> | 2020 - 2022'
    },
    description: {
      'us': `Developed and released web and mobile app ${getLink('https://www.akitatv.com/', 'Akita TV')}.<br />${shared.akitaTV}`,
      'no': `Utviklet og utgitt web- og mobilapp ${getLink('https://www.akitatv.com/', 'Akita TV')}.<br />${shared.akitaTV}`
    }
  }, {
    title: {
      'us': 'Senior Developer',
      'no': 'Seniorutvikler'
    },
    location: {
      'us': `${getLink('https://www.stagesflight.com/', 'Stages Cycling')} | Norway | 2019 - 2020`,
      'no': `${getLink('https://www.stagesflight.com/', 'Stages Cycling')} | Norge | 2019 - 2020`
    },
    description: {
      'us': shared.stages,
      'no': shared.stages
    }
  }, {
    title: {
      'us': 'Senior System Developer',
      'no': 'Senior Systemutvikler'
    },
    location: {
      'us': `${getLink('https://www.equinor.com/', 'Equinor ASA')} | Norway | 2018`,
      'no': `${getLink('https://www.equinor.com/no', 'Equinor ASA')} | Norge | 2018`
    },
    description: {
      'us': shared.equinor,
      'no': shared.equinor
    }
  }, {
    title: {
      'us': 'Senior Developer',
      'no': 'Seniorutvikler'
    },
    location: {
      'us': `${getLink('https://www.linkedin.com/company/netconnect-as/', 'NetConnect AS')} | Norway | 2011 - 2013`,
      'no': `${getLink('https://www.linkedin.com/company/netconnect-as/', 'NetConnect AS')} | Norge | 2011 - 2013`
    },
    description: {
      'us': 'Worked on a SharePoint 2010 cloud solution.',
      'no': 'Jobbet med en SharePoint 2010 skyløsning.'
    }
  }, {
    title: {
      'us': 'IT Manager',
      'no': 'IT-Ansvarlig'
    },
    location: {
      'us': `${getLink('https://www.northdata.com/Cadmei+Software+Entwicklung+f%C3%BCr+Medizinsysteme+GmbH,+Neuwied/Amtsgericht+Montabaur+HRB+22964', 'Cadmei GmbH')} | Germany | 2009 - 2011`,
      'no': `${getLink('https://www.northdata.com/Cadmei+Software+Entwicklung+f%C3%BCr+Medizinsysteme+GmbH,+Neuwied/Amtsgericht+Montabaur+HRB+22964', 'Cadmei GmbH')} | Tyskland | 2009 - 2011`
    },
    description: {
      'us': 'Managed the network, storage and server infrastructure.',
      'no': 'Ansvarlig for administrasjon av nettverk-, lagring- og serverinfrastruktur.'
    }
  }, {
    title: {
      'us': 'Head Engineer',
      'no': 'Overingeniør'
    },
    location: {
      'us': `${getLink('https://www.uio.no/english/', 'University of Oslo')} | Norway | 2008 - 2009`,
      'no': `${getLink('https://www.uio.no/', 'Universitetet i Oslo')} | Norge | 2008 - 2009`
    },
    description: {
      'us': 'Developed a PHP/MySQL archiving tool, and general IT management.',
      'no': 'Utviklet et PHP/MySQL arkiveringsverktøy, samt generell IT-administrasjon.'
    }
  }, {
    title: {
      'us': 'Senior Server Analyst',
      'no': 'Senior Serveranalytiker'
    },
    location: {
      'us': `${getLink('https://www.dell.com/', 'Dell')} | Sweden, Ireland | 2005 - 2007`,
      'no': `${getLink('https://www.dell.com/no-no', 'Dell')} | Sverige, Irland | 2005 - 2007`
    },
    description: {
      'us': 'Developed a PHP/MySQL intranet solution, and network support.',
      'no': 'Utviklet en PHP/MySQL intranettløsning, samt nettverksstøtte.'
    }
  }, {
    title: {
      'us': 'Network Administrator',
      'no': 'Nettverksadministrator'
    },
    location: {
      'us': `${getLink('https://www.oslo.kommune.no/english/', 'Oslo Kommune')} | Norway | 2002 - 2003`,
      'no': `${getLink('https://www.oslo.kommune.no/', 'Oslo Kommune')} | Norge | 2002 - 2003`
    },
    description: {
      'us': 'Migrated systems from NT4 to Windows 2000.',
      'no': 'Migrerte systemer fra NT4 til Windows 2000.'
    }
  }, {
    title: {
      'us': 'Printer Support Technician',
      'no': 'Printer Supporttekniker'
    },
    location: {
      'us': `${getLink('https://www.sykes.com/', 'Sykes Enterprises, Inc')} | Sweden | 2001`,
      'no': `${getLink('https://www.sykes.com/', 'Sykes Enterprises, Inc')} | Sverige | 2001`
    },
    description: {
      'us': 'Supported IT-departments with network printer issues.',
      'no': 'Støttet IT-avdelinger med problemer med nettverksprintere.'
    }
  }]
};

export default TimelineItemsL;
