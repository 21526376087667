import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import AboutC from '../../const/about';
import NavLinksC from '../../const/nav-links';

import { GetDarkModeTitle, NavL } from '../../lang/nav';

import HtmlService from '../../services/HtmlService';
import SettingsService from '../../services/SettingsService';

import './Nav.scss';

function Nav() {
  const location = useLocation().pathname;

  const [ darkMode, setDarkMode] = useState(SettingsService.IsDarkMode);

  useEffect(() => SettingsService.ApplyDarkMode(darkMode), [ darkMode ])

  function handleLanguageChange(event: any) {
    SettingsService.Language = event.target.value;
  }

  // function showSubMenu(event: any) {
  //   if (matchMedia && !matchMedia('(hover: hover)').matches) {
  //     const target  = (event.target as HTMLElement);
  //     const div     = HtmlService.GetParentDIV(target);
  //     const subMenu = (div ? div.nextElementSibling as HTMLElement : null);

  //     if (subMenu) {
  //       const isVisible       = (subMenu.style.display === 'block');
  //       subMenu.style.display = (isVisible ? 'none' : 'block');
  //     }
  //   }
  // }

  return (
    <nav id="Nav">
      <div className="flex flex-reverse">
        <figure className="logo" title={AboutC.company}>
          <Link to={NavLinksC.home.url}>
            <img
              className="middle padding-10"
              src="/icon-transparent-64x64.png"
              alt={NavL.LogoAlt[SettingsService.Language]} loading="lazy"
            />
          </Link>
        </figure>

        <ul className="center flex-1 flex flex-nowrap links">
          <li>
            <Link
              to={NavLinksC.home.url}
              className={HtmlService.GetSelectedClass(NavLinksC.home.url, location)}
            >{NavLinksC.home.title[SettingsService.Language]}</Link>
          </li>

          <li>
            <Link
              to={NavLinksC.about.url}
              className={HtmlService.GetSelectedClass(NavLinksC.about.url, location)}
            >{NavLinksC.about.title[SettingsService.Language]}</Link>
          </li>

          <li>
            <Link
              to={NavLinksC.appsAkitaTV.url}
              className={HtmlService.GetSelectedClass(NavLinksC.appsAkitaTV.url, location)}
            >{NavLinksC.apps.title[SettingsService.Language]}</Link>
          </li>
          {/* <li className="subnav">
            <div className="clickable flex flex-nowrap" onClick={showSubMenu}>
              <span
                className={HtmlService.GetSelectedClass(NavLinksC.apps.url, location)}
              >{NavLinksC.apps.title[SettingsService.Language]}</span>

              <FontAwesomeIcon icon={solid('angle-down')} />
            </div>

            <div className="subnav-content">
              <div className="flex flex-vertical">
                <Link
                  to={NavLinksC.appsAkitaTV.url}
                  className={HtmlService.GetSelectedClass(NavLinksC.appsAkitaTV.url, location)}
                >{NavLinksC.appsAkitaTV.title[SettingsService.Language]}</Link>

                <Link
                  to={NavLinksC.appsVoyaPlayer.url}
                  className={HtmlService.GetSelectedClass(NavLinksC.appsVoyaPlayer.url, location)}
                >{NavLinksC.appsVoyaPlayer.title[SettingsService.Language]}</Link>
              </div>
            </div>
          </li> */}

          <li>
            <Link
              to={NavLinksC.articles.url}
              className={HtmlService.GetSelectedClass(NavLinksC.articles.url, location)}
            >{NavLinksC.articles.title[SettingsService.Language]}</Link>
          </li>
        </ul>

        <div className="flex flex-nowrap right">
          <FontAwesomeIcon
            className="clickable dark-icon"
            icon={solid('circle-half-stroke')}
            title={GetDarkModeTitle()}
            onClick={() => setDarkMode(!darkMode)}
          />

          <div className="flag flex flex-nowrap">
            <select
              className="middle"
              title={NavL.LanguageTitle[SettingsService.Language]}
              value={SettingsService.Language}
              onChange={handleLanguageChange}
            >
              <option value="us">English</option>
              <option value="no">Norsk</option>
            </select>

            <img
              className="middle"
              src={SettingsService.GetLanguageFlag()}
              alt={NavL.CountryFlagAlt[SettingsService.Language]} loading="lazy"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
