import NavLinksL from "../lang/nav-links";

const NavLinksC = {
  about: {
    title: NavLinksL.about,
    url: '/about'
  },
  apps: {
    title: NavLinksL.apps,
    url: '/apps'
  },
  appsAkitaTV: {
    title: 'Akita TV',
    url: '/apps/akita-tv'
  },
  appsVoyaPlayer: {
    title: 'Voya Player',
    url: '/apps/voya-player'
  },
  articles: {
    title: NavLinksL.articles,
    url: '/articles'
  },
  home: {
    title: NavLinksL.home,
    url: '/'
  }
};

export default NavLinksC;
