import AboutC from "../const/about";
import ExternalUrlsC from "../const/external-urls";

import { ArticleHashTagsC, ArticleTitlesC } from "../const/articles";

class HtmlService {

  public static GetExternalUrl(provider: string, id: string): string {
    const title = ArticleTitlesC.get(id);

    if (!title) {
      return '';
    }

    const url          = `${AboutC.url}articles/${id}`;
    const encodedTitle = encodeURIComponent(title);
    const encodedUrl   = encodeURIComponent(url);

    switch(provider) {
      case 'facebook':
        return `${ExternalUrlsC.facebook}sharer.php?u=${encodedUrl}`;
      case 'linkedin':
        return `${ExternalUrlsC.linkedin}shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`;
      case 'mail':
        return `mailto:?subject=${encodedTitle}&body=${encodeURIComponent(`${title}\n${url}`)}`;
      case 'twitter':
        const hashtags = `#jammary #articles ${ArticleHashTagsC.get(id)}`;
        const twitter  = `${title} ${url} ${hashtags} ${AboutC.twitter.handle}`;

        return `${ExternalUrlsC.twitter}intent/tweet?url=${encodedUrl}&text=${encodeURIComponent(twitter)}`;
      default:
        break;
    }

    return '';
  }

  public static GetParentDIV(element: HTMLElement): HTMLElement {
    if (element.parentElement && (element.nodeName.toUpperCase() !== 'DIV')) {
      return HtmlService.GetParentDIV(element.parentElement);
    }

    return element;
  }

  public static GetSelectedClass(path: string, location: string): string {
    if (path === '/') {
      return (path === location ? 'selected' : '');
    }

    return (location.startsWith(path) ? 'selected' : '');
  }

  public static ReplaceIcons(markdown: HTMLElement) {
    markdown.querySelectorAll('img').forEach((img) => {
      const alt = img.getAttribute('alt');

      if (alt && alt === 'social-media-icon') {
        const a = img.parentElement;

        if (a && (a.nodeName.toUpperCase() === 'A')) {
          a.style.marginRight = '20px';
        }
      }
    });
  }

  public static ReplaceLinks(markdown: HTMLElement, setSrc: any) {
    markdown.querySelectorAll('a').forEach((a) => {
      a.setAttribute('target', '_blank');
      a.setAttribute('rel', 'noreferrer noopener');

      const href = a.getAttribute('href');

      if (href && href.startsWith('/articles/')) {
        a.onclick = (e) => {
          e.preventDefault();
          setSrc(href.substring(10));
        };
      }
    });
  }

}

export default HtmlService;
