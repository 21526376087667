import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';

import FooterLinksC from './const/footer-links';
import NavLinksC from './const/nav-links';

import About from './pages/About/About';
import Articles from './pages/Articles/Articles';
import Apps from './pages/Apps/Apps';
import Home from './pages/Home/Home';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';

import SettingsService from './services/SettingsService';

import Footer from './components/footer/Footer';
import Nav from './components/nav/Nav';
import ScrollToTopButton from './components/scroll-to-top-button/ScrollToTopButton';

import './App.scss';

function App() {
  const [ language, setLanguage ] = useState(SettingsService.Language);

  useEffect(() => {
    SettingsService.Language$.subscribe((lang: string) => setLanguage(lang));
  }, [ language ])

  return (
    <BrowserRouter>
      <div id="App" className="flex flex-vertical full-size">
        <Nav />

        <div id="AppMain" className="flex-1 flex flex-vertical">
          <div className="flex-1">
            <Routes>
              <Route path={NavLinksC.about.url} element={<About />} />
              <Route path={`${NavLinksC.apps.url}/:id`} element={<Apps />} />
              <Route path={`${NavLinksC.articles.url}/:id`} element={<Articles />} />
              <Route path={NavLinksC.articles.url} element={<Articles />} />
              <Route path={FooterLinksC.privacy.url} element={<Privacy />} />
              <Route path={FooterLinksC.terms.url} element={<Terms />} />
              <Route path={`${NavLinksC.home.url}*`} element={<Home />} />
            </Routes>
          </div>

          <ScrollToTopButton buttonID="AppScrollToTop" topID="AppMain"></ScrollToTopButton>

          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
