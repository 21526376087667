import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import ReactMarkdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkGfm from 'remark-gfm'

import { useEffect, useState } from 'react';

import ExternalTitlesL from '../../lang/external-titles';

import HtmlService from '../../services/HtmlService';
import SettingsService from '../../services/SettingsService';

import './Markdown.scss';

function Markdown(props: { id: string, src: string, setSrc: any }) {
  const [ content, setContent ] = useState('');

  useEffect(() => {
    fetch(props.src)
    .then((result) => result.text())
    .then((content) => {
      setContent(content);

      const markdown = document.getElementById('Markdown');
  
      if (markdown) {
        HtmlService.ReplaceIcons(markdown);
        HtmlService.ReplaceLinks(markdown, props.setSrc);
      }
    });
  }, [ props, content ])

  if (!content) {
    return null;
  }

  return (
    <div id="Markdown">
      <div className="flex padding-top-20">
      <div className="padding-left-right-10">
          <a href={HtmlService.GetExternalUrl('twitter', props.id)} title={ExternalTitlesL.twitter[SettingsService.Language]} target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={brands('twitter-square')} size="2x" />
          </a>
        </div>

        <div className="padding-left-right-10">
          <a href={HtmlService.GetExternalUrl('linkedin', props.id)} title={ExternalTitlesL.linkedin[SettingsService.Language]} target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={brands('linkedin')} size="2x" />
          </a>
        </div>

        <div className="padding-left-right-10">
          <a href={HtmlService.GetExternalUrl('facebook', props.id)} title={ExternalTitlesL.facebook[SettingsService.Language]} target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={brands('facebook-square')} size="2x" />
          </a>
        </div>

        <div className="padding-left-right-10">
          <a href={HtmlService.GetExternalUrl('mail', props.id)} title={ExternalTitlesL.mail[SettingsService.Language]} target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={solid('envelope')} size="2x" />
          </a>
        </div>
      </div>

      <ReactMarkdown
        rehypePlugins={[rehypeHighlight]}
        remarkPlugins={[remarkGfm]}
      >{content}</ReactMarkdown>
    </div>
  );
}

export default Markdown;
