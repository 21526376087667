import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useEffect, useState } from 'react';

import { NavL } from '../../lang/nav';

import SettingsService from '../../services/SettingsService';

import './ScrollToTopButton.scss';

function ScrollToTopButton(props: { buttonID: string, topID: string }) {
  const [ button, setButton] = useState<HTMLElement | null>(null);
  const [ top,    setTop]    = useState<HTMLElement | null>(null);

  useEffect(() => {
    let buttonElement: HTMLElement | null;

    if (!button && props.buttonID) {
      buttonElement = document.getElementById(props.buttonID);

      if (buttonElement) {
        setButton(buttonElement);
      }
    }

    if (!top && props.topID) {
      const topElement = document.getElementById(props.topID);
  
      if (topElement) {
        topElement.addEventListener('scroll', () => {
          if (buttonElement && topElement) {
            buttonElement.style.display = (topElement.scrollTop > 0 ? 'block' : 'none');
          }
        });

        setTop(topElement);
      }
    }
  }, [ props, button, top ])

  function scrollToTop() {
    if (top) {
      top.scrollTop = 0;
    }
  }

  return (
    <div id={props.buttonID} className="scroll-to-top">
      <div className="clickable scroll-to-top-button" onClick={scrollToTop}>
        <FontAwesomeIcon icon={solid('arrow-up')} />

        {NavL.ScrollToTop[SettingsService.Language]}
      </div>
    </div>
  );
}

export default ScrollToTopButton;
