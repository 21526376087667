import { AppDetailsI } from "../../interfaces/app";

import AppDetailsL from "../../lang/app/app-details";

const AppDetailsC = new Map<string, AppDetailsI>([
  [ "akita-tv", AppDetailsL.akitaTV ],
  [ "voya-player", AppDetailsL.voyaPlayer ]
]);

export default AppDetailsC;
