import { TimelineItemI } from '../../interfaces/timeline';

import SettingsService from '../../services/SettingsService';
import UtilsService from '../../services/UtilsService';

import './Timeline.scss';

function Timeline(props: { title: string, items: Array<TimelineItemI> }) {
  return (
    <div id="Timeline">
      <h2>{props.title}</h2>

      <div className="timeline">
        {props.items.map((item, i) => (
          <div key={i} className="timeline-container">
            <div className="timeline-content">
              <h3>{UtilsService.GetValueIfDefined(
                item.title[SettingsService.Language], item.title
              )}</h3>

              <h4 dangerouslySetInnerHTML={{
                __html: UtilsService.GetValueIfDefined(
                  item.location[SettingsService.Language], item.location
                )
              }}></h4>

              <p dangerouslySetInnerHTML={{
                __html: UtilsService.GetValueIfDefined(
                  item.description[SettingsService.Language], item.description
                )
              }}></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
