const AboutC = {
  author: 'Adam A. Jammary',
  company: 'Jammary Studio',
  github: 'https://github.com/adamajammary',
  hackerrank: 'https://www.hackerrank.com/AdamJammary',
  linkedin: 'https://www.linkedin.com/in/adamjammary',
  mail: 'mailto:adam@jammary.com',
  twitter: {
    handle: '@AdamJammary',
    url: 'https://twitter.com/AdamJammary'
  },
  url: 'https://www.jammary.com/'
};

export default AboutC;
